import React, { Component } from 'react'

export default class SurveyRadioFieldWithRichTextLabel extends Component {

  translateCSS(css) {
    // TODO: Implement css -> jsx styling
    let jsonCSS = {}
    if (css !== '') {
      try {
        jsonCSS = JSON.parse(css)
      } catch (e) {
        return {}
      }
    }
    return jsonCSS;
  }

  getFormikValue(attribute_id, formikProps) {
    let formikPropsSurvey = formikProps.values["survey"];
    return formikPropsSurvey[attribute_id];
  }

  setFormikValue(value, attribute_id, formikProps) {
    let formikPropsSurvey = formikProps.values["survey"];
    formikPropsSurvey[attribute_id] = value;
    formikProps.setFieldValue('survey', formikPropsSurvey);
  }

  renderQuestionLabel(question) {
    return (
      <div className="label-wrapper">
        <label style={{fontSize: "16px", marginBottom: "16px"}} className={`label-english ${question.required ? 'required':''}`} dangerouslySetInnerHTML={{__html: question.label}}/>
      </div>
    );
  }

  render() {
    const { type, formikProps, question, erroring } = this.props;
    let value = this.getFormikValue(question.attribute_id, formikProps);
    console.log(value)
    let radioOptions = question.options.map(option =>
      <div
        className={`col-xs-12 col-md-3 radio-field`}
        style={{verticalAlign: "top"}}
        key={`radio-question-${question.gid}-option-${option.value}`}
      >
        <div className="radio-option-wrapper" style={{paddingBottom: "7px"}}>
          <img
            src={ value === option.value ? "/registration/radios/radio-active.svg" : "/registration/radios/radio.svg" }
            style={{cursor:"pointer"}}
            onClick={() => {
              this.setFormikValue(option.value, question.attribute_id, formikProps, type);
            }}
          />
          <div className="radio-option-label">{option.label}</div>
        </div>
      </div>
    )

    return(
      <div className={`${question.custom_class === 'radio-block-table' ? 'radio-block' : question.custom_class}`}>
        <div
          className={`col-xs-12 radio-label ${question.custom_class === 'radio-block-table' ? 'radio-block-table-label' : ''}`}
          style={this.translateCSS(question.custom_css)}
        >
          {this.renderQuestionLabel(question)}
        </div>
        {radioOptions}
        <div className={`col-xs-12`} />
        {
          erroring ?
          <div className="col-xs-12">
            <div className="registration-field-error">
              <>Please select an option to continue.</>
            </div>
          </div>
          :
          <></>
        }
      </div>
    )
  }
}
